import {liveBind} from 'evite';

$.fn.reverse = [].reverse;

class Slider {
  get defaults() {
    return {
      slideDur: 4000,
      fadeDur: 300,
    };
  }

  constructor(opts) {
    this.fading = false;
    this.slides = $('.slide', this.el);
    this.totalSlides = this.slides.length;
    this.pagerList = $('.page-list', this.el);
    this.build_index();
    this.pagers = $(this.pagerList).find('.page').reverse();
    this.slides.eq(0).css('opacity', 1);
    this.pagers.eq(0).addClass('active');
    this.activeSlide = 0;
    this.waitForNext(this.defaults);
    $('.slide-list', this.el).hover(
      () => this.stop(),
      () => this.waitForNext()
    );
    $(window).on('blur', () => this.waitForNext());
    liveBind(opts.el, 'click', '.page', this.go_to_slide);
  }

  build_index = () => {
    for (let i = 0; i < this.totalSlides; ++i) {
      this.pagerList.prepend(
        `<li class="page" data-target="${i}"><a href="javascript:void(0)" ></a></li>`
      );
    }
  };

  animateSlides = (activeIndex, newIndex) => {
    const cleanUp = () => {
      this.slides.eq(activeIndex).removeClass('active').removeAttr('style');
      this.slides.eq(newIndex).addClass('active').removeAttr('style');
      this.activeSlide = newIndex;
      this.fading = false;
      this.waitForNext();
    };

    if (this.fading || activeIndex === newIndex) {
      return false;
    }

    this.fading = true;

    $(this.pagers).removeClass('active').eq(newIndex).addClass('active');
    $(this.slides).eq(activeIndex).css('z-index', 3);
    $(this.slides)
      .eq(newIndex)
      .css({
        'z-index': 2,
        opacity: 1,
        position: 'absolute',
      })
      .show();

    $(this.slides)
      .eq(activeIndex)
      .animate(
        {
          opacity: 0,
        },
        this.defaults.fadeDur,
        () => {
          cleanUp();
        }
      );
  };

  stop = () => clearTimeout(this.slideTimer);

  waitForNext = (defaults) => {
    try {
      this.stop();
    } catch (error) {
      const e = error;
    }

    this.slideTimer = setTimeout(() => {
      this.animateSlides(this.activeSlide, (this.activeSlide + 1) % this.totalSlides);
    }, this.defaults.slideDur);
  };

  go_to_slide = (e) => {
    e.preventDefault();

    clearTimeout(this.slideTimer);
    const newSlide = $(e.target).attr('data-target');
    this.animateSlides(this.activeSlide, newSlide);
  };
}

function slide_width() {
  if ($('.hero-container') && $(window).width() > 1600) {
    $('.hero-container .inner').width($(window).width());
  }
}

$(document).ready(() => {
  $('.slider').each(function () {
    new Slider({
      el: this,
    });
  });

  slide_width();

  $(window).resize(() => {
    slide_width();
  });
});
